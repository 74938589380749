import { template as template_329303cff21248abb3fb160a6d6e2170 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_329303cff21248abb3fb160a6d6e2170(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
