import { template as template_313c7dfe6e424d6ba81d0e60e8aa6ede } from "@ember/template-compiler";
const SidebarSectionMessage = template_313c7dfe6e424d6ba81d0e60e8aa6ede(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
