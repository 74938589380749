import { template as template_1a43c16fc5384fe498f97d4c87736d8f } from "@ember/template-compiler";
const FKControlMenuContainer = template_1a43c16fc5384fe498f97d4c87736d8f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
